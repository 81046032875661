import ELCollectionStore from "../base/elcollectionstore";
import { store } from "../../../Root"
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'
import ELWorkout from "../../model/elworkout";

export default class ELUserWorkoutsStore extends ELCollectionStore {
  getCollectionQuery() {
    return FirestorePathManager.getUserWorkoutsCollection(store.getState().auth.user).orderBy("createdDate", "desc");
  }
  getTargetObject() {
    return new ELWorkout();
  }
}