export default class ELRoutine {
  static from(json){
    return Object.assign(new ELRoutine(), json);
  }
  getTotalSets() {
    var count = 0;
    this.exerciseGroups.forEach(group => {
      var sets = 0;
      group.exercises.forEach(routineExercise => {
        sets = Math.max(sets, routineExercise.sets.length);
      });
      count += sets;
    });
    return count;
  }
  getTotalExercises() {
    var count = 0;
    this.exerciseGroups.forEach(group => {
      count += group.exercises.length;
    });
    return count;
  }
}