import { myFirestore } from "../../firebase/firebase";

var FirestorePathManager = {
    getGlobalExercisesCollection() {
        return this._getCollectionReference("global/exercises/all");
    },
    getUsersCollection() {
        return this._getCollectionReference("users");
    },
    getUser(user) {
        return this._getDocumentReference(this._buildUserSpecificPath(user));
    },
    getUserExercisesCollection(user) {
        return this._getCollectionReference(this._buildUserSpecificPath(user, "exercises"));
    },
    getUserRoutinesCollection(user) {
        return this._getCollectionReference(this._buildUserSpecificPath(user, "routines"));
    },
    getUserRoutine(user, routineUuid) {
        return this._getDocumentReference(this._buildUserSpecificPath(user, `routines/${routineUuid}`));
    },
    getUserWorkoutsCollection(user) {
        return this._getCollectionReference(this._buildUserSpecificPath(user, "history"));
    },
    getUserWorkout(user, workoutUuid) {
        return this._getDocumentReference(this._buildUserSpecificPath(user, `history/${workoutUuid}`));
    },
    getPlansCollection(user) {
        return this._getCollectionReference(this._buildPlansPath());
    },
    getPlan(user, planUuid) {
        return this._getDocumentReference(`${this._buildPlansPath()}/${planUuid}`);
    },

    // Utils

    _getCollectionReference(path) {
        return myFirestore.collection(path);
    },
    _getDocumentReference(path) {
        return myFirestore.doc(path);
    },
    _buildPlansPath() {
        return "plans";
    },
    _buildUserSpecificPath(user, path) {
        if (path) {
            return `users/${user.id}/${path}`;
        }
        return `users/${user.id}`;
    }
}
export default FirestorePathManager;