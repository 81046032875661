export default class ELDocumentStore {

  oneTimeListeners = [];
  // Parsing
  parsingOngoing = false;
  snapshotsToParse = [];
  // Firebase
  registration = null;

  getDocumentReference(itemId) {
    return null;
  }
  getTargetObject() {
    return null;
  }
  destroy() {
    this.parsingOngoing = false;
    this._removeSnapshotListener();
  }
  getItem(itemId, listener) {
    if (listener) {
      console.log("Requesting one-time document for object " + this.getTargetObject().constructor.name);
      this._safelyAdd(this.oneTimeListeners, listener);
    } else {
      console.log("Requesting snapshot document for object " + this.getTargetObject().constructor.name);
    }
    this._removeSnapshotListener();
    this._addSnapshotListener(itemId);
  }

  // Firebase

  _removeSnapshotListener() {
    if (this.registration) {
      this.registration();
    }
    this.registration = null;
  }
  _addSnapshotListener(itemId) {
    this._removeSnapshotListener();
    var documentReference = this.getDocumentReference(itemId);
    if (documentReference) {
      // Wait until cache is parsed first
      var cacheFinishedListener = () => {
        this.registration = documentReference.onSnapshot({ includeMetadataChanges: true }, (snapshot, e) => {
          if (e) {
            this._notifyError(false, e);
          } else if (snapshot) {
            this._enqueueSnapshot(snapshot, false, null)
          }
        });
      };
      // Try resolving the required data from cache first
      documentReference.get({ source: 'cache' })
        .then(result => {
          // Parse cache if we have something
          this._enqueueSnapshot(result, true, cacheFinishedListener);
        })
        .catch(error => {
          console.log(error);
          cacheFinishedListener();
        });
    }
  }
  _enqueueSnapshot(snapshot, fromCache, onParsingComplete) {
    this.snapshotsToParse.push(snapshot);
    if (!this.parsingOngoing) {
      this.parsingOngoing = true;
      this._doParseDocument(this.snapshotsToParse.shift(), false, fromCache, onParsingComplete)
    }
  }
  _doParseDocument(snapshot, somethingPending, fromCache, onParsingComplete) {
    try {
      var parsed = Object.assign(this.getTargetObject(), snapshot.data());
      if (!parsed) {
        this._notifyError(fromCache, new Error("Item is null"));
        if (onParsingComplete) {
          onParsingComplete();
        }
      } else {
        // Decorate
        var onDecorationComplete = () => {
          var onComplete = (decoratedItem) => {
            if (this.snapshotsToParse.length <= 0) {
              this.parsingOngoing = false;
              // Make sure decorated item is valid
              if (decoratedItem) {
                this._itemLoaded(decoratedItem);
                this._notifyItemLoaded(decoratedItem, somethingPending || snapshot.metadata.hasPendingWrites, fromCache);
                if (onParsingComplete) {
                  onParsingComplete();
                }
              }
            } else {
              this._doParseDocument(this.snapshotsToParse.shift(), somethingPending || snapshot.metadata.hasPendingWrites, fromCache, onParsingComplete);
            }
          }
          var onError = (error) => {
            this._notifyError(fromCache, error);
            if (onParsingComplete) {
              onParsingComplete();
            }
          }
          onDecorationComplete.onComplete = onComplete;
          onDecorationComplete.onError = onError;
        };
        this._decorateItem(parsed, onDecorationComplete);
      }
    } catch (error) {
      this._notifyError(fromCache, error);
      if (onParsingComplete) {
        onParsingComplete();
      }
    }
  }
  _decorateItem(item, onDecorationComplete) {
    onDecorationComplete();
    onDecorationComplete.onComplete(item);
  }
  _itemLoaded(item) {
    // No-op
  }

  // Utils

  _safelyAdd(collection, item) {
    if (item && !collection.includes(item)) {
      collection.push(item);
    }
  }

  // Notifications

  _notifyError(fromCache, error) {
    console.log(error);
    this.oneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoadingError(error);
    });
    this.oneTimeListeners.length = 0;
  }
  _notifyItemLoaded(item, hasPendingWrites, fromCache) {
    this.oneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoaded(item, fromCache);
    });
    this.oneTimeListeners.length = 0;
  }
}