import ELCollectionStore from "../base/elcollectionstore";
import ELExercise from "../../model/elexercise"
import { store } from "../../../Root"
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'

export default class ELUserExercisesStore extends ELCollectionStore {
  getCollectionQuery() {
    return FirestorePathManager.getUserExercisesCollection(store.getState().auth.user).orderBy("name");
  }
  getTargetObject() {
    return new ELExercise();
  }
}