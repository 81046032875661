const STORE_KEY = "everlogapp.com:state";

var LocalStoreManager = {
  saveValue(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  },
  loadValue(key) {
    try {
      return localStorage.getItem(key)
    } catch (error) {
      console.log(error);
    }
  },
  loadState() {
    console.log("Loading local state");
    try {
      let serializedState = localStorage.getItem(STORE_KEY);
      if (!serializedState) {
        console.log("Creating default local state");
        return this._initializeState();
      }
      console.log("Local state: " + serializedState);
      return JSON.parse(serializedState);
    } catch (error) {
      console.log(error);
      return this._initializeState();
    }
  },
  saveState(state) {
    try {
      let serializedState = JSON.stringify(state);
      localStorage.setItem(STORE_KEY, serializedState);
    } catch (error) {
      console.log(error);
    }
  },
  _initializeState() {
    return {
      // Initial state object
    }
  }
}
export default LocalStoreManager;