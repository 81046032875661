import React, { Component } from "react";

class LandingHero extends Component {
  render() {
    return (
      <section id="hero-7" className="bg-fixed hero-section division">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div
              className="col-md-5 col-lg-5 animated"
              data-animation="fadeInUp"
              data-animation-delay={400}
            >
              <div className="hero-img">
                <img
                  className="img-fluid"
                  src="images/hero-7-img.png"
                  alt="hero"
                />
              </div>
            </div>
            <div className="col-md-7 col-lg-6 offset-lg-1">
              <div className="hero-txt white-color text-right">
                <h2
                  className="h2-lg animated"
                  data-animation="fadeInUp"
                  data-animation-delay={300}
                >
                  A Radically Better Tracker
                </h2>
                <p
                  className="p-lg animated"
                  data-animation="fadeInUp"
                  data-animation-delay={400}
                  >
                  Workout trackers often distract more than assist you at 
                  the gym. We're not about wasting your time or effort, and 
                  that's why thousands of people already use Everlog
                </p>
                <div
                  className="hero-stores-badge animated"
                  data-animation="fadeInUp"
                  data-animation-delay={500}
                >
                  {/* <a href="/#" className="store">
                    <img
                      className="appstore-original"
                      src="images/store_badges/appstore.png"
                      width={160}
                      height={50}
                      alt="appstore-logo"
                    />
                  </a> */}
                  <a href="http://play.google.com/store/apps/details?id=com.everlog" target="_blank" rel="noopener noreferrer" className="store">
                    <img
                      className="googleplay-original"
                      src="images/store_badges/googleplay.png"
                      width={171}
                      height={50}
                      alt="googleplay-logo"
                    />
                  </a>
                  {/* <span className="os-version">
                    * Requires iOS 7.0 or higher
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingHero;