import React, { Component } from "react";

// https://themes-pixeden.com/font-demos/7-stroke/
class LandingFeatures extends Component {
  render() {
    return (
      <section id="features-3" className="wide-70 featuress-section division">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 section-title">
              <h2 className="h2-lg">And Much More</h2>
              {/* <p>
                Aliquam a augue suscipit, luctus neque purus ipsum neque dolor
                primis libero tempus, tempor posuere ligula varius
              </p> */}
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={300}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-note2" />
                <h5 className="h5-md">Weekly Summary</h5>
                <p className="grey-color">
                  Your weekly activity at a glance
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={400}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-magic-wand" />
                <h5 className="h5-md">Workout Assistant</h5>
                <p className="grey-color">
                  Log your workout without unlocking your device
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={500}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-target" />
                <h5 className="h5-md">Training Goals</h5>
                <p className="grey-color">
                  1RM weight suggestions based on your goals
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={600}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-gym" />
                <h5 className="h5-md">Weight Prediction</h5>
                <p className="grey-color">
                  Automatic history-based weight suggestions
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={700}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-clock" />
                <h5 className="h5-md">History</h5>
                <p className="grey-color">
                  Your entire workout history in your pocket
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={800}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-refresh-2" />
                <h5 className="h5-md">Routines</h5>
                <p className="grey-color">
                  Quickly and easily setup your routines
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={900}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-help2" />
                <h5 className="h5-md">Fast Support</h5>
                <p className="grey-color">
                  Got a question or an issue? We're here to help!
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 animated"
              data-animation="fadeInUp"
              data-animation-delay={1000}
            >
              <div className="fbox-3 box-icon-lg green-hover">
                <div className="box-line" />
                <span className="pe-7s-piggy" />
                <h5 className="h5-md">Free Pro Trial</h5>
                <p className="grey-color">
                  Enjoy a free 7-day trial. Cancel anytime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingFeatures;