import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";

const styles = () => ({
  divider: {
    backgroundColor: "var(--main_gray_3)!important"
  }
});

class PriceHeader extends Component {
  render() {
    const { classes, price, period, top, opacity = 1 } = this.props;
    return (
      <div>
        <div
          style={
            top
              ? { marginBottom: 16, marginTop: 34, opacity: opacity }
              : { marginBottom: 30, opacity: opacity }
          }
        >
          <sup style={price !== 0 ? { color: "var(--main_accent)" } : {}}>
            £
          </sup>
          <span
            className="price"
            style={price !== 0 ? { color: "var(--main_accent)" } : {}}
          >
            {price}
          </span>
          <sup
            className="validity"
            style={price !== 0 ? { color: "var(--main_accent)" } : {}}
          >
            {" "}
            / {period}
          </sup>
        </div>
        {!top && <Divider className={classes.divider} />}
      </div>
    );
  }
}

class LandingPricing extends Component {
  render() {
    const { classes } = this.props;
    return (
      <section
        id="pricing-1"
        className="bg-lightgrey wide-100 pricing-section division"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 section-title">
              <h2 className="h2-lg">Train Like a Pro</h2>
              {/* <p>
                Take your training to the next level with muscle goals <br /> and
                workout plans.
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
              <div className="row">
                <div
                  className="col-md-6 animated"
                  data-animation="fadeInRight"
                  data-animation-delay={400}
                >
                  <div className="pricing-table m-bottom-40">
                    <div className="pricing-plan m-bottom-10">
                      <h4 className="h4-md">
                        Everlog <span>Free</span>
                      </h4>
                      <PriceHeader
                        price={0}
                        period={"month"}
                        top={true}
                        classes={classes}
                      />
                      <PriceHeader opacity={0} classes={classes} />
                    </div>

                    <ul className="features">
                      <li>
                        <i className="fas fa-check" /> Unlimited history &
                        routines
                      </li>
                      <li>
                        <i className="fas fa-check" /> Historic weight
                        suggestions
                      </li>
                      <li>
                        <i className="fas fa-check" /> Statistics
                      </li>
                      <li className="disabled-option">
                        <i className="fas fa-check" style={{ opacity: 0 }} />{" "}
                        Workout plans
                      </li>
                      <li className="disabled-option">
                        <i
                          className="fa fa-check"
                          style={{ opacity: 0 }}
                          aria-hidden="true"
                        />{" "}
                        Muscle training goals
                      </li>
                      <li className="disabled-option">
                        <i
                          className="fa fa-check"
                          style={{ opacity: 0 }}
                          aria-hidden="true"
                        />{" "}
                        Decimal weight increments
                      </li>
                    </ul>

                    {/* <a href="/#" className="btn btn-tra-black">
                      Get Started
                    </a> */}
                  </div>
                </div>
                <div
                  className="col-md-6 animated"
                  data-animation="fadeInLeft"
                  data-animation-delay={400}
                >
                  <div className="pricing-table m-bottom-40">
                    <div className="pricing-plan m-bottom-10">
                      <h4 className="h4-md">
                        Everlog{" "}
                        <span style={{ color: "var(--main_accent)" }}>
                          Pro
                        </span>
                      </h4>
                      <PriceHeader
                        price={3.99}
                        period={"month or"}
                        top={true}
                        classes={classes}
                      />
                      <PriceHeader
                        price={35.99}
                        period={"year"}
                        classes={classes}
                      />
                    </div>

                    <ul className="features">
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Unlimited history & routines
                      </li>
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Historic weight suggestions
                      </li>
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Statistics
                      </li>
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Workout plans
                      </li>
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Muscle training goals
                      </li>
                      <li>
                        <i
                          className="fas fa-check"
                          style={{ color: "var(--main_accent)" }}
                        />{" "}
                        Decimal weight increments
                      </li>
                    </ul>
                    {/* <a href="/#" className="btn btn-lightgreen">
                      Upgrade to PRO
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="pricing-notice text-center">
                <p className="p-md">
                  <span>Note!</span> Prices may vary from location to location
                  due to local taxation laws and conversion rates from UK
                  Pounds.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(LandingPricing);
