import { myFirebaseApp } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";
import { myFirestore } from "../firebase/firebase";
import ELUser from '../data/model/eluser'; 
import ELDataStore from '../data/datastores/eldatastore'

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_CLEAR = "REGISTER_CLEAR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_CLEAR = "LOGIN_CLEAR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const USER_UPDATE = "USER_UPDATE";

const requestRegister = () => {
  return {
    type: REGISTER_REQUEST
  };
};

const registerError = error => {
  return {
    type: REGISTER_FAILURE,
    message: error.message
  };
};

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const loginError = error => {
  return {
    type: LOGIN_FAILURE,
    message: error.message
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutError = error => {
  return {
    type: LOGOUT_FAILURE,
    message: error.message
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const requestRegisterClear = () => {
  return {
    type: REGISTER_CLEAR
  };
};

export const requestLoginClear = () => {
  return {
    type: LOGIN_CLEAR
  };
};

const receiveUser = user => {
  return {
    type: USER_UPDATE,
    user
  };
};

export const registerUser = (name, email, password) => dispatch => {
  console.log("Registering user");
  dispatch(requestRegister());
  myFirebaseApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(result => {
      console.log("User registered. Setting name");
      myFirebaseApp.auth().currentUser.updateProfile({
        displayName: name
      })
      .then(result => {
        console.log("User name set. Sending verification email");
        myFirebaseApp.auth().currentUser.sendEmailVerification()
        .then(result => {
          console.log("Verification email sent. Finishing registration");
          finishLocalLogin(dispatch);
        })
        .catch(error => {
          console.log(error);
          dispatch(registerError(error));
        });
      })
      .catch(error => {
        console.log(error);
        dispatch(registerError(error));
      });
    })
    .catch(error => {
      console.log(error);
      dispatch(registerError(error));
    });
};

export const loginUser = (email, password) => dispatch => {
  console.log("Logging in user");
  dispatch(requestLogin());
  myFirebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(_ => {
      finishLocalLogin(dispatch);
    })
    .catch(error => {
      console.log(error);
      dispatch(loginError(error));
    });
};

export const loginUserWithGoogle = () => dispatch => {
  console.log("Logging in user with Google");
  dispatch(requestLogin());
  var provider = new myFirebase.auth.GoogleAuthProvider();
  myFirebaseApp
    .auth()
    .signInWithPopup(provider)
    .then(_ => {
      finishLocalLogin(dispatch);
    })
    .catch(error => {
      console.log(error);
      dispatch(loginError(error));
    });
};

export const logoutUser = () => dispatch => {
  console.log("Logging out user");
  dispatch(requestLogout());
  myFirebaseApp
    .auth()
    .signOut()
    .then(() => {
      // Make sure to clear the datastores when the user logs out
      ELDataStore.destroy();
      dispatch(receiveLogout());
    })
    .catch(error => {
      console.log(error);
      dispatch(logoutError(error));
    });
};

export const verifyAuth = () => dispatch => {
  console.log("Verifying auth state");
  dispatch(verifyRequest());
  var listener = myFirebaseApp.auth().onAuthStateChanged(user => {
    console.log("Firebase auth state changed");
    if (user) {
      finishLocalLogin(dispatch);
    }
    dispatch(verifySuccess());
    // Unregister this listener as it's only used the first time to check valid registration
    listener();
  });
};

export function saveUser(dispatch, user) {
  console.log("Saving user profile");
  dispatch(receiveUser(user));
  myFirestore.collection("users").doc(user.id).set(user.asMap(), { merge: true });
}

function finishLocalLogin(dispatch) {
  var localUser = new ELUser(myFirebaseApp.auth().currentUser);
  console.log("Refreshing user profile: id=" + localUser.id);
  // Dispatch once to load page immediately
  dispatch(receiveLogin(localUser));
  // Refresh profile from Firestore
  var onStoreItemListener = () => {
    var onItemLoaded = (item, fromCache) => {
      // Make sure the user is valid
      var finalUser = item.isValid() ? item : localUser;
      saveUser(dispatch, finalUser);
      console.log("Finishing local login: user=" + JSON.stringify(finalUser));
      dispatch(receiveLogin(finalUser));
    }
    var onItemLoadingError = (error) => {
      dispatch(receiveLogin(localUser));
    }
    onStoreItemListener.onItemLoaded = onItemLoaded;
    onStoreItemListener.onItemLoadingError = onItemLoadingError;
  };
  ELDataStore.userStore.getItem(localUser.id, onStoreItemListener)
}
