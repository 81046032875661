import ELDocumentStore from "../base/eldocumentstore";
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'
import ELWorkout from "../../model/elworkout";
import { store } from "../../../Root"

export default class ELUserWorkoutStore extends ELDocumentStore {
    getDocumentReference(itemId) {
        return FirestorePathManager.getUserWorkout(store.getState().auth.user, itemId);
    }
    getTargetObject() {
        return new ELWorkout();
    }
}