import ELDocumentStore from "../base/eldocumentstore";
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'
import ELRoutine from "../../model/elroutine";
import { store } from "../../../Root"

export default class ELUserRoutineStore extends ELDocumentStore {
    getDocumentReference(itemId) {
        return FirestorePathManager.getUserRoutine(store.getState().auth.user, itemId);
    }
    getTargetObject() {
        return new ELRoutine();
    }
}