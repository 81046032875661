import moment from "moment";

export default class ELWorkout {
  static from(json){
    return Object.assign(new ELWorkout(), json);
  }
  getDurationMillis() {
    return this.completedDate - this.createdDate;
  }
  getTotalWeight() {
    var weight = 0.0;
    this.routine.exerciseGroups.forEach(exerciseGroup => {
      exerciseGroup.exercises.forEach(exercise => {
        exercise.sets.forEach(set => {
          if (set.weight > 0) {
            weight += (set.reps * set.weight);
          }
        });
      });
    });
    return weight;
  }
  getTotalSets() {
    var sets = 0;
    this.routine.exerciseGroups.forEach(exerciseGroup => {
      exerciseGroup.exercises.forEach(exercise => {
        sets += exercise.sets.length;
      });
    });
    return sets;
  }
  getMaxWeight() {
    var weight = 0.0;
    this.routine.exerciseGroups.forEach(exerciseGroup => {
      exerciseGroup.exercises.forEach(exercise => {
        exercise.sets.forEach(set => {
          if (set.weight > 0) {
            weight = Math.max(weight, set.weight);
          }
        });
      });
    });
    return weight;
  }
  getCategoryCounts() {
    var categories = {};
    this.routine.exerciseGroups.forEach(exerciseGroup => {
      exerciseGroup.exercises.forEach(exercise => {
        let category = exercise.exercise.category;
        var count = categories[category];
        if (!count) {
          count = 0;
        }
        count++;
        categories[category] = count;
      });
    });
    return categories;
  }
  getCompletedDateWithNoHourTimestamp() {
    return moment(moment(this.completedDate).format("L"), "MM/DD/YYYY").toDate();
  }
  getCompletedDateWithMonthOnlyTimestamp() {
    return moment(this.completedDate).startOf("month").toDate();
  }
  getCompletedDateWithYearOnlyTimestamp() {
    return moment(this.completedDate).startOf("month").startOf("year").toDate();
  }
  getDay() {
    return moment(this.completedDate).date();
  }
  getMonth() {
    return moment(this.completedDate).month();
  }
  getYear() {
    return moment(this.completedDate).year();
  }
  isThisWeek() {
    return moment(this.completedDate).isSame(new Date(), "week");
  }
  isThisMonth() {
    return moment(this.completedDate).isSame(new Date(), "month");
  }
  isThisYear() {
    return moment(this.completedDate).isSame(new Date(), "year");
  }
  inRange(range) {
    switch (range) {
      case 0:
        // Week
        return this.isThisWeek();
      case 1:
        // Month
        return this.isThisMonth();
      case 2:
        // Year
        return this.isThisYear();
      case 3:
        // Overall
        return true;
      default:
        return false;
    }
  }
}