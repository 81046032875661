import ELDocumentStore from "./base/eldocumentstore";
import ELUser from "../model/eluser";
import { store } from "../../Root"
import FirestorePathManager from '../../managers/firebase/firestorepathmanager'

export default class ELUserStore extends ELDocumentStore {
    getDocumentReference() {
        return FirestorePathManager.getUser(store.getState().auth.user);
    }
    getTargetObject() {
        return new ELUser(null);
    }
}