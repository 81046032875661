import React from "react";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import App from "./components/App";
import configureStore from "./configureStore";
import LocalStoreManager from './managers/store/localstoremanager'

const store = configureStore(LocalStoreManager.loadState());
store.subscribe(() => {
  LocalStoreManager.saveState(store.getState());
});
export { store };

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default Root;
