import React, { Component } from "react";

class LandingFeedback extends Component {
  render() {
    return (
      <section id="reviews-1" className="wide-100 reviews-section division">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 section-title">
              <h2 className="h2-lg">We're Growing Fast</h2>
              <p>
                Welcome to the community!
              </p>
            </div>
          </div>
          <div className="reviews-carousel">
            <div className="center slider">
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-1.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Nathan Johnson</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  I have tried and tested at least 50 other fitness apps out there.
                  Everlog is hands down #1 best fitness app I have found in 2019
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-2.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Elliot Gordon</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  This app just does what I want, and lets you review your performance
                  really easily. Absolutely worth a try.
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-3.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Miquel Mauri Richarte</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  It is a great way of recording your progress and routines.
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-4.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Jono Moo</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  Very easy to create routines and plans. All very configurable.
                  Even my personal trainer can use it!
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-5.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Tao Chen</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  One of the best out there. specially for people who program their own workout.
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-6.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Isaac Fuller</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  Hands down best gym based workout ever. Honestly couldn't recommend enough.
                  Love it. Thank you for making it.
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-3.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Rob Clay</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  Straightforward and easy to use, tracks interesting statistics and keeps
                  you motivated to increase the weight/challenge of workouts.
                </p>
              </div>
              <div className="review-1">
                <div className="testimonial-avatar text-center">
                  {/* <img src="images/review-author-3.jpg" alt="review-author" /> */}
                  <p className="testimonial-autor">Hussain Memon</p>
                </div>
                <div className="app-rating m-bottom-15">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p>
                  This app is great, very easy to use and has a clean interface unlike other
                  apps on the market. The best workout app I have ever used.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingFeedback;