import ELCollectionStore from "../base/elcollectionstore";
import { store } from "../../../Root"
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'
import ELRoutine from "../../model/elroutine";

export default class ELUserRoutinesStore extends ELCollectionStore {
  getCollectionQuery() {
    return FirestorePathManager.getUserRoutinesCollection(store.getState().auth.user).orderBy("name");
  }
  getTargetObject() {
    return new ELRoutine();
  }
}