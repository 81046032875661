import React, { Component } from "react";
import { store } from "../../Root";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from '@material-ui/icons/Menu';
import { HashLink as Link } from 'react-router-hash-link';

// eslint-disable-next-line
class LoginBtn extends Component {
  handleDashboard = () => {
    var user = this.props.user;
    if (user && user.isValid) {
      this.props.history.push("/dashboard");
    } else {
      this.props.history.push("/signin");
    }
  };
  render() {
    const { user, isVerifying } = this.props;
    return (
      <button
        className="nav-link pre-link btnDashboard"
        onClick={this.handleDashboard}
      >
        <Avatar
          className="avatarLanding"
          src={store.getState().auth.user.photoUrl}
        />
        {user && user.isValid
          ? "Dashboard"
          : `${isVerifying ? "--" : "Sign In"}`}
      </button>
    );
  }
}

class LandingNav extends Component {
  render() {
    return (
      <header id="header" className="header">
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-tra">
          <div className="container">
            <Link smooth to="#hero-7" className="navbar-brand logo-white">
              <img
                src="images/logo.png"
                width={130}
                alt="header-logo"
              />
            </Link>
            <Link smooth to="#hero-7" className="navbar-brand logo-black">
              <img
                src="images/logo.png"
                width={130}
                alt="header-logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <MenuIcon style={{ color: "#ccc" }} />
            </button>
            <div
              id="navbarSupportedContent"
              className="collapse navbar-collapse"
            >
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="#content-1">
                      Why MobiCom
                    </a>
                    <a className="dropdown-item" href="#video-1">
                      Watch Video
                    </a>
                    <a className="dropdown-item" href="#statistic-2">
                      How It Works
                    </a>
                    <a className="dropdown-item" href="#team-1">
                      Our Team
                    </a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="#reviews-1">
                      Reviews
                    </a>
                  </div>
                </li> */}
                <li className="nav-item nl-simple">
                  <Link smooth className="nav-link" to="#hero-7">
                    Get the App
                  </Link>
                </li>
                <li className="nav-item nl-simple">
                  <Link smooth className="nav-link pre-link" to="#pricing-1">
                    Upgrade
                    <span />
                  </Link>
                </li>
                <li className="nav-item nl-simple">
                  <Link smooth className="nav-link" to="#faqs-1">
                    Support
                  </Link>
                </li>
                {/* <LoginBtn {...this.props} /> */}
                {/* <li className="header-socials clearfix">
                  <span>
                    <a href="/#" className="ico-facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </span>
                  <span>
                    <a href="/#" className="ico-twitter">
                      <i className="fab fa-twitter" />
                    </a>
                  </span>
                  <span>
                    <a href="/#" className="ico-dribbble">
                      <i className="fab fa-dribbble" />
                    </a>
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default LandingNav;