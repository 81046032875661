import React, { Component } from "react";

class LandingDownload extends Component {
  render() {
    return (
      <section id="download-3" className="wide-100 download-section">
        <div className="bg-scroll bg-inner division">
          <div className="container white-color">
            <div className="row d-flex align-items-center">
              <div
                className="col-md-5 col-lg-4 animated"
                data-animation="fadeInRight"
                data-animation-delay={500}
              >
                <div className="download-3-img text-center ind-15">
                  <img
                    className="img-fluid"
                    src="images/image-14.png"
                    alt="download"
                  />
                </div>
              </div>
              <div className="col-md-7 col-lg-7 offset-lg-1">
                <div className="download-txt ind-30">
                  <h2
                    className="h2-xs animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={300}
                  >
                    Ready to push your limits?
                  </h2>
                  <p
                    className="p-lg animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={400}
                  >
                    Download the app now for FREE to start tracking your progress and 
                    finally achieve your fitness goals.
                  </p>
                  <div
                    className="stores-badge animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={500}
                  >
                    {/* <a href="/#" className="store">
                      <img
                        className="appstore-white"
                        src="images/store_badges/appstore-tra-white.png"
                        width={155}
                        height={50}
                        alt="appstore-logo"
                      />
                    </a> */}
                    <a href="http://play.google.com/store/apps/details?id=com.everlog" target="_blank" rel="noopener noreferrer" className="store">
                      <img
                        className="googleplay-white"
                        src="images/store_badges/googleplay-tra-white.png"
                        width={164}
                        height={50}
                        alt="googleplay-logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingDownload;