import React, { Component } from "react";

class LandingFooter extends Component {
  render() {
    return (
      <footer id="footer-2" className="wide-60 footer division">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8 col-lg-7">
              <div className="footer-info p-right-30 m-bottom-40">
                <img
                  src="images/footer-logo.png"
                  width={173}
                  alt="footer-logo"
                />
                <p>
                  We built Everlog to bridge the gap between getting the most out of your 
                  training and tracking it seamlessly, with minimal distractions. We
                  believe that analysing and reflecting on your progress is essential 
                  to achieving your goals and we're thrilled to have you on board!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 offset-lg-1">
              <div className="footer-socials-links text-right m-bottom-25">
                <h5 className="h5-sm">
                  <a href="https://www.facebook.com/everlogapp" target="_blank" rel="noopener noreferrer" className="foo-facebook">
                    Facebook
                  </a>
                </h5>
                <h5 className="h5-sm">
                  <a href="https://twitter.com/everlogapp" target="_blank" rel="noopener noreferrer" className="foo-twitter">
                    Twitter
                  </a>
                </h5>
                {/* <h5 className="h5-sm">
                  <a href="/#" className="foo-instagram">
                    Instagram
                  </a>
                </h5>
                <h5 className="h5-sm">
                  <a href="/#" className="foo-drrrible">
                    Dribbble
                  </a>
                </h5> */}
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="row">
              <div className="col-md-5">
                <div className="footer-copyright">
                  <p>
                    © 2020 <span>Everlog Ltd.</span> All Rights Reserved
                  </p>
                  {/* <p>
                    Design with <i className="fas fa-heart" /> by
                    <a href="/#">DSAThemes</a>
                  </p> */}
                </div>
              </div>
              <div className="col-md-7">
                <div className="footer-links text-right">
                  <ul className="foo-links clearfix">
                    <li>
                      <a href="https://www.termsfeed.com/privacy-policy/68420a39232d3fc1b3e6d56275fed431" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="https://www.termsfeed.com/terms-conditions/ab88f4b9d47638276ae7284d4a7ed3f8" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default LandingFooter;