import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// import ProtectedRoute from "./home/ProtectedRoute";

// import LoginPage from "./signin/LoginPage";
// import RegisterPage from "./login/Register"

import LandingPage from "./landing/LandingPage";
// import DashboardPage from "./home/DashboardPage";
// import AccountPage from "./home/account/AccountPage";
// import RoutineDetailsPage from "./home/routine/RoutineDetailsPage";
// import WorkoutDetailsPage from "./home/history/WorkoutDetailsPage";

function App(props) {
  // const { isAuthenticated, isVerifying } = props;
  return (
    <Switch>
      {/* <Route path="/signin" component={LoginPage} /> */}
      {/* <Route path="/register" component={RegisterPage} /> */}
      {/* <ProtectedRoute
        exact
        path="/account"
        component={AccountPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        path="/routines/:uuid"
        component={RoutineDetailsPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        path="/history/:uuid"
        component={WorkoutDetailsPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        path="/:tab"
        component={DashboardPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      /> */}
      <Route path="/" component={LandingPage} />
    </Switch>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(App);
