export default class ELUser {
  static from(json){
    return Object.assign(new ELUser(null), json);
  }
  constructor(firebaseUser) {
    if (firebaseUser) {
      this.id = firebaseUser.uid;
      this.displayName = firebaseUser.displayName;
      this.email = firebaseUser.email;
      this.photoUrl = firebaseUser.photoURL;
      this.createdDate = new Date(firebaseUser.metadata.creationTime).getTime();
      this.subscription = null
    }
  }
  asMap() {
    var map = {
      id: this.id,
      displayName: this.displayName,
      email: this.email,
      photoUrl: this.photoUrl
    };
    if (this.subscription) {
      map.subscription = this.subscription;
    }
    return map;
  }
  firstName() {
    if (this.displayName) {
      return this.displayName.split(" ")[0];
    }
    return null;
  }
  isValid() {
    if (this.id) {
      return true;
    }
    return false;
  }
}
