import ELUserStore from "./eluserstore"
import ELExercisesStore from "./exercises/elexercisestore"
import ELUserRoutineStore from "./routines/eluserroutinestore";
import ELUserRoutinesStore from "./routines/eluserroutinesstore";
import ELUserWorkoutStore from "./history/eluserworkoutstore";
import ELUserWorkoutsStore from "./history/eluserworkoutsstore";

var ELDataStore = {
    userStore: new ELUserStore(),
    exercisesStore: new ELExercisesStore(),
    routineStore: new ELUserRoutineStore(),
    routinesStore: new ELUserRoutinesStore(),
    workoutStore: new ELUserWorkoutStore(),
    workoutsStore: new ELUserWorkoutsStore(),
    destroy() {
        this.userStore.destroy();
        this.exercisesStore.destroy();
        this.routineStore.destroy();
        this.routinesStore.destroy();
        this.workoutStore.destroy();
        this.workoutsStore.destroy();
    }
}
export default ELDataStore;