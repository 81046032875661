import React, { Component } from "react";

class LandingContact extends Component {
  render() {
    return (
      <section
        id="contacts-1"
        className="bg-fixed bg-lightgrey wide-80 contacts-section division"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 section-title">
              <h2 className="h2-lg">Need Help?</h2>
              <p>
                We'd love to hear from you! Send us a message and we will get
                back to you as soon as possible
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2">
              <div className="form-holder">
                <form name="contactform" className="row contact-form">
                  <div id="input-name" className="col-lg-6">
                    <input
                      type="text"
                      name="nickname"
                      className="form-control nickname ohnohoney"
                      placeholder="Nickname"
                    />
                    <input
                      type="text"
                      name="name"
                      className="form-control name"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div id="input-email" className="col-lg-6">
                    <input
                      type="text"
                      name="email"
                      className="form-control email"
                      placeholder="Email Address*"
                    />
                  </div>
                  <div id="input-subject" className="col-md-12 input-subject">
                    <select
                      id="inlineFormCustomSelect1"
                      name="Subject"
                      className="custom-select subject"
                    >
                      <option>This question is about...</option>
                      <option>Registering/Authorising</option>
                      <option>Using Application</option>
                      <option>Troubleshooting</option>
                      <option>Backup/Restore</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div id="input-message" className="col-lg-12 input-message">
                    <textarea
                      className="form-control message"
                      name="message"
                      rows={6}
                      placeholder="Your Message ..."
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-lg-12 m-top-15 form-btn text-right">
                    <button
                      id="contactSendBtn"
                      type="submit"
                      className="btn btn-lightgreen submit"
                    >
                      Send Message
                    </button>
                  </div>
                  <div className="col-lg-12 contact-form-msg">
                    <span className="loading" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingContact;
