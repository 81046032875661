import React, { Component } from "react";

class LandingContent2 extends Component {
  render() {
    return (
      <section id="content-3" className="bg-fixed content-section">
        <div className="first-row bg-lightgrey wide-60 division">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div
                className="col-md-6 animated"
                data-animation="fadeInRight"
                data-animation-delay={500}
              >
                <div className="content-img">
                  <img
                    className="img-fluid"
                    src="images/image-12.png"
                    alt="content"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-txt ind-45">
                  <h2
                    className="h2-xs animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={300}
                  >
                    Statistics
                  </h2>
                  <p
                    className="animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={400}
                  >
                    Making sure you are progressing with your training is essential
                    to long term results. With our detailed and insightful statistics
                    you will always be on the right track.
                  </p>
                  {/* <h5
                    className="h5-md animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={500}
                  >
                    With our statistics you can:
                  </h5> */}
                  <div
                    className="box-list m-top-15 animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={600}
                  >
                    <div className="box-list-icon">
                      <i className="fas fa-genderless" />
                    </div>
                    <p>
                      Get insights about the <b>weight used</b> during workouts
                    </p>
                  </div>
                  <div
                    className="box-list animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={600}
                  >
                    <div className="box-list-icon">
                      <i className="fas fa-genderless" />
                    </div>
                    <p>
                      Discover which <b>muscle groups</b> you train the least that need improvement
                    </p>
                  </div>
                  <div
                    className="box-list animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={700}
                  >
                    <div className="box-list-icon">
                      <i className="fas fa-genderless" />
                    </div>
                    <p>
                      Keep track of your <b>session durations</b>
                    </p>
                  </div>
                  <div
                    className="box-list animated"
                    data-animation="fadeInLeft"
                    data-animation-delay={800}
                  >
                    <div className="box-list-icon">
                      <i className="fas fa-genderless" />
                    </div>
                    <p>
                      View <b>exercise weight history</b> and your <b>1RM</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="second-row wide-60 division">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className="content-txt ind-45">
                  <h2
                    className="h2-xs animated"
                    data-animation="fadeInRight"
                    data-animation-delay={300}
                  >
                    Available on all your devices
                  </h2>
                  <p
                    className="animated"
                    data-animation="fadeInRight"
                    data-animation-delay={400}
                  >
                    An enim nullam tempor sapien gravida donec enim ipsum porta
                    justo integer at odio velna vitae auctor integer congue
                  </p>
                  <h5
                    className="h5-md animated"
                    data-animation="fadeInRight"
                    data-animation-delay={500}
                  >
                    Use on any devices
                  </h5>
                  <p
                    className="animated"
                    data-animation="fadeInRight"
                    data-animation-delay={600}
                  >
                    An nullam tempor sapien, eget gravida donec enim ipsum porta
                    justo integer at odio velna vitae auctor integer congue
                    magna undo. Gravida magna lacus odio ac risus auctor
                  </p>
                  <div
                    className="app-devices clearfix animated"
                    data-animation="fadeInRight"
                    data-animation-delay={700}
                  >
                    <i className="fas fa-tablet-alt f-tablet" />
                    <i className="fas fa-mobile-alt f-phone" />
                    <div className="app-devices-desc">
                      <p className="p-small">
                        Available on iPhone, iPad and all Android devices from
                        5.5
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 animated"
                data-animation="fadeInLeft"
                data-animation-delay={500}
              >
                <div className="content-img">
                  <img
                    className="img-fluid"
                    src="images/apple-watch.png"
                    alt="content"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    );
  }
}

export default LandingContent2;