import { Subject } from 'rxjs';

export default class ELCollectionStore {

  items = [];

  itemsLoadedSubscriber = new Subject();
  oneTimeListeners = [];
  // Parsing
  itemsParsedOnce = false;
  cacheLoadedOnce = false;
  snapshotLoadedOnce = false;
  parsingOngoing = false;
  snapshotsToParse = [];
  // Firebase
  registration = null;

  getCollectionQuery() {
    return null;
  }
  getTargetObject() {
    return null;
  }
  getItemsLoadedSubscription() {
    return this.itemsLoadedSubscriber;
  }
  destroy() {
    this._removeSnapshotListener();
    this.snapshotLoadedOnce = false;
    this.itemsParsedOnce = false;
    this.cacheLoadedOnce = false;
    this.parsingOngoing = false;
    this.items.length = 0;
  }
  getItems(listener) {
    if (listener) {
      console.log("Requesting one-time collection for object " + this.getTargetObject().constructor.name);
      this._safelyAdd(this.oneTimeListeners, listener);
    } else {
      console.log("Requesting snapshot collection for object " + this.getTargetObject().constructor.name);
    }
    if (this.snapshotLoadedOnce || this.cacheLoadedOnce) {
      // Wait until items finish parsing
      if (!this.parsingOngoing) {
        if (listener) {
          listener();
          listener.onItemsLoaded(this.items, false)
        } else {
          // sendItemsLoadedEvent(false);
          this.itemsLoadedSubscriber.next(this.items);
        }
      }
    } else {
      this._addSnapshotListener();
    }
  }

  // Firebase

  _checkCacheLoadedOnce() {
    if (!this.cacheLoadedOnce) {
      this.items.length = 0;
    }
    this.cacheLoadedOnce = true;
  }
  _removeSnapshotListener() {
    if (this.registration) {
      this.registration();
    }
    this.registration = null;
  }
  _addSnapshotListener() {
    this._removeSnapshotListener();
    var collectionQuery = this.getCollectionQuery();
    if (collectionQuery) {
      // Wait until cache is parsed first
      var cacheFinishedListener = () => {
        // Reset this here to not send snapshot events to clients after cache is parsed
        this.itemsParsedOnce = false;
        // Continue with snapshot
        this.registration = collectionQuery.onSnapshot({ includeMetadataChanges: true }, (snapshot, e) => {
          if (!this.snapshotLoadedOnce) {
            this.items.length = 0;
          }
          this.snapshotLoadedOnce = true;
          if (e) {
            this._notifyError(false, e);
          } else if (snapshot) {
            this._enqueueSnapshot(snapshot, false, null)
          }
        });
      };
      // Try resolving the required data from cache first
      collectionQuery.get({ source: 'cache' })
        .then(result => {
          this._checkCacheLoadedOnce();
          // Parse cache if we have something
          this._enqueueSnapshot(result, true, cacheFinishedListener);
        })
        .catch(error => {
          console.log(error);
          this._checkCacheLoadedOnce();
          cacheFinishedListener();
        });
    }
  }
  _enqueueSnapshot(snapshot, fromCache, onParsingComplete) {
    this.snapshotsToParse.push(snapshot);
    if (!this.parsingOngoing) {
      this.parsingOngoing = true;
      this._doParseDocument(this.snapshotsToParse.shift(), 0, fromCache, onParsingComplete)
    }
  }
  _doParseDocument(snapshot, index, fromCache, onParsingComplete) {
    if (index < snapshot.docChanges().length) {
      var documentChange = snapshot.docChanges()[index];
      var document = documentChange.doc;
      if (document.exists) {
        try {
          // Parse item
          var parsed = Object.assign(this.getTargetObject(), document.data());
          var newIndex = documentChange.newIndex;
          var oldIndex = documentChange.oldIndex;
          //var hasPendingWrites = document.metadata.hasPendingWrites;
          // Decorate
          var onDecorationComplete = () => {
            var onComplete = (decoratedItem) => {
              // Make sure decorated item is valid
              if (decoratedItem) {
                switch (documentChange.type) {
                  case "added": {
                    this.items[newIndex] = decoratedItem;
                    // if (mItemsParsedOnce) {
                    //   notifyItemAdded(newIndex, decoratedItem, hasPendingWrites, fromCache);
                    // }
                    break;
                  }
                  case "modified": {
                    this.items.splice(oldIndex, 1);
                    this.items[newIndex] = decoratedItem;
                    // if (mItemsParsedOnce) {
                    //   notifyItemModified(oldIndex, newIndex, decoratedItem, hasPendingWrites, fromCache);
                    // }
                    break;
                  }
                  case "removed": {
                    this.items.splice(oldIndex);
                    // if (mItemsParsedOnce) {
                    //   notifyItemRemoved(oldIndex, decoratedItem, hasPendingWrites, fromCache);
                    // }
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
              this._doParseDocument(snapshot, index + 1, fromCache, onParsingComplete);
            }
            var onError = (error) => {
              console.log(error);
              this._doParseDocument(snapshot, index + 1, fromCache, onParsingComplete);
            }
            onDecorationComplete.onComplete = onComplete;
            onDecorationComplete.onError = onError;
          };
          this._decorateItem(parsed, onDecorationComplete);
        } catch (error) {
          this._notifyError(fromCache, error);
          if (onParsingComplete) {
            onParsingComplete();
          }
        }
      } else {
        // Invalid document, proceed to next
        this._doParseDocument(snapshot, index + 1, fromCache, onParsingComplete);
      }
    } else {
      if (this.snapshotsToParse.length <= 0) {
        // We have finished parsing all snapshots, so notify completion
        this.itemsParsedOnce = true;
        this.parsingOngoing = false;
        this._notifySnapshotReady(fromCache);
        if (onParsingComplete) {
          onParsingComplete();
        }
      } else {
        this._doParseDocument(this.snapshotsToParse.shift(), 0, fromCache, onParsingComplete);
      }
    }
  }
  _decorateItem(item, onDecorationComplete) {
    onDecorationComplete();
    onDecorationComplete.onComplete(item);
  }

  // Utils

  _safelyAdd(collection, item) {
    if (item && !collection.includes(item)) {
      collection.push(item);
    }
  }

  // Notifications

  _notifyError(fromCache, error) {
    console.log(error);
    this.oneTimeListeners.forEach(listener => {
      listener()
      listener.onItemLoadingError(error);
    });
    this.oneTimeListeners.length = 0;
  }
  _notifySnapshotReady(fromCache) {
    this.oneTimeListeners.forEach(listener => {
      listener()
      listener.onItemsLoaded(this.items, fromCache);
    });
    if (!fromCache) {
      // Allow one-time listeners to get the cached versions too.
      this.oneTimeListeners.length = 0;
    }
    this.itemsLoadedSubscriber.next(this.items);
  }
}