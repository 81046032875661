import React, { Component } from "react";

class LandingFAQ extends Component {
  render() {
    return (
      <section id="faqs-1" className="bg-fixed wide-40 faqs-section division">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 section-title">
              <h2 className="h2-lg">Common Questions</h2>
              {/* <p>
                Aliquam a augue suscipit, luctus neque purus ipsum neque dolor
                primis libero tempus, tempor posuere ligula varius
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="questions-holder ind-30">
                <div
                  className="question animated"
                  data-animation="fadeInUp"
                  data-animation-delay={300}
                >
                  <h5 className="h5-sm">Can I cancel my subscription at any time?</h5>
                  <p>
                    Absolutely. Subscriptions run on a monthly or yearly basis and can be
                    cancelled at any time and will be ended from the following month.
                  </p>
                </div>
                <div
                  className="question animated"
                  data-animation="fadeInUp"
                  data-animation-delay={400}
                >
                  <h5 className="h5-sm">Can I get a trial first?</h5>
                  <p>
                    Yes, you can sign up for a 7-day free trial during which your
                    subscription can be cancelled free of charge at any time.
                  </p>
                </div>
                <div
                  className="question animated"
                  data-animation="fadeInUp"
                  data-animation-delay={500}
                >
                  <h5 className="h5-sm">
                    What are my paying options?
                  </h5>
                  <p>
                    You can pay quickly and securely with your Google Pay account.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="questions-holder ind-30">
                <div
                  className="question animated"
                  data-animation="fadeInUp"
                  data-animation-delay={600}
                >
                  <h5 className="h5-sm">What are Training Goals?</h5>
                  <p>
                    Training goals give you history-based weight suggestions during
                    workouts to ensure your muscles are working in the most effective way.
                    Our suggestions are based on your 1RM - the max weight you can lift
                    for one repetition for an exercise.
                  </p>
                </div>
                <div
                  className="question animated"
                  data-animation="fadeInUp"
                  data-animation-delay={700}
                >
                  <h5 className="h5-sm">
                    Can I browse existing workout plans?
                  </h5>
                  <p>
                    At the moment we only support custom workout plans so you would have to
                    create it yourself. However, we are working towards making the plans shareable
                    very soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingFAQ;