import ELCollectionStore from "../base/elcollectionstore";
import ELExercise from "../../model/elexercise"
import FirestorePathManager from '../../../managers/firebase/firestorepathmanager'
import ELUserExercisesStore from "./eluserexercisesstore"
import { combineLatest, Subject } from 'rxjs';

export default class ELExercisesStore extends ELCollectionStore {

  allItemsReady = new Subject();
  userExercisesStore = new ELUserExercisesStore();

  constructor() {
    super();
    this._observeItemsLoaded()
  }

  observeAllItemsReady() {
    return this.allItemsReady;
  }
  getCollectionQuery() {
    return FirestorePathManager.getGlobalExercisesCollection().orderBy("name");
  }
  getTargetObject() {
    return new ELExercise();
  }
  destroy() {
    super.destroy();
    this.userExercisesStore.destroy();
  }
  getItems(listener) {
    this.userExercisesStore.getItems(listener);
    super.getItems(listener);
  }

  // Observers

  _observeItemsLoaded() {
    // Ensures that we have the global exercises plus the user's personal exercises in one list
    combineLatest(this.getItemsLoadedSubscription(),
      this.userExercisesStore.getItemsLoadedSubscription(), (global, user) => {
        var merged = global.concat(user);
        merged.sort((a, b) => (a.name > b.name) ? 1 : -1)
        return merged;
      })
      .subscribe((result) => {
        this.allItemsReady.next(result);
      }, (error) => {
        this.allItemsReady.error(error);
      });
  }
}