import React, { Component } from "react";

class LandingContent1 extends Component {
  render() {
    return (
      <section
        id="content-1"
        className="bg-fixed wide-60 content-section division"
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="content-txt ind-45">
                <h2
                  className="h2-xs animated"
                  data-animation="fadeInRight"
                  data-animation-delay={300}
                >
                  Workout Plans
                </h2>
                <p
                  className="animated"
                  data-animation="fadeInRight"
                  data-animation-delay={400}
                >
                  Our advanced planner allows you to schedule your future
                  workout sessions day by day
                </p>
                <div
                  className="box-list m-top-15 animated"
                  data-animation="fadeInRight"
                  data-animation-delay={500}
                >
                  <div className="box-list-icon">
                    <i className="fas fa-genderless" />
                  </div>
                  <p>Create a <b>day by day</b> training plan</p>
                </div>
                <div
                  className="box-list m-top-15 animated"
                  data-animation="fadeInRight"
                  data-animation-delay={600}
                >
                  <div className="box-list-icon">
                    <i className="fas fa-genderless" />
                  </div>
                  <p>Plan up to <b>25 weeks</b> ahead</p>
                </div>
                <div
                  className="box-list m-top-15 animated"
                  data-animation="fadeInRight"
                  data-animation-delay={700}
                >
                  <div className="box-list-icon">
                    <i className="fas fa-genderless" />
                  </div>
                  <p><b>Start it</b> and let the app <b>guide you</b> along the way</p>
                </div>
                <div
                  className="box-list m-top-15 animated"
                  data-animation="fadeInRight"
                  data-animation-delay={800}
                >
                  <div className="box-list-icon">
                    <i className="fas fa-genderless" />
                  </div>
                  <p><b>Visualize</b> your progress</p>
                </div>
                {/* <div
                  className="quote animated"
                  data-animation="fadeInRight"
                  data-animation-delay={500}
                >
                  <p>
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean magna ligula eget dolor suscipit egestas viverra
                    dolor iaculis luctus magna suscipit egestas "
                  </p>
                  <div className="quote-avatar">
                    <img src="images/quote-avatar.jpg" alt="quote-avatar" />
                  </div>
                  <div className="quote-author">
                    <h5 className="h5-xs">Leslie Serpas</h5>
                    <span className="grey-color">Designer of MobiCom</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="content-img animated"
                data-animation="fadeInLeft"
                data-animation-delay={500}
              >
                <img
                  className="img-fluid"
                  src="images/image-02.png"
                  alt="content"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingContent1;
