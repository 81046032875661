import React, { Component } from "react";
import { connect } from "react-redux";
import { myAnalytics } from "../../firebase/firebase";
import LocalStoreManager from "../../managers/store/localstoremanager";
import LandingNav from "./LandingNav";
import LandingHero from "./LandingHero";
// import LandingBrands from "./LandingBrands";
import LandingContent1 from "./LandingContent1";
import LandingContent2 from "./LandingContent2";
// import LandingScreenshots from "./LandingScreenshots";
// import LandingVideo from "./LandingVideo";
import LandingFeatures from "./LandingFeatures";
import LandingPricing from "./LandingPricing";
// import LandingStatistics from "./LandingStatistics";
import LandingFeedback from "./LandingFeedback";
// import LandingTeam from "./LandingTeam";
// import LandingApps from "./LandingApps";
import LandingDownload from "./LandingDownload";
import LandingFAQ from "./LandingFAQ";
import LandingContact from "./LandingContact";
// import LandingNewsletter from "./LandingNewsletter";
import LandingFooter from "./LandingFooter";

class LandingPage extends Component {
  componentDidMount() {
    myAnalytics.logEvent("page_view", { page_title: "screen_landing" });
  }
  componentWillUnmount() {
    // Make sure to refresh the landing page when the component reappears, otherwise animations won't replay
    LocalStoreManager.saveValue("needsRefresh", "true");
  }
  render() {
    var needsRefresh = LocalStoreManager.loadValue("needsRefresh") || "true";
    if (needsRefresh === "true") {
      LocalStoreManager.saveValue("needsRefresh", "false");
      window.location.reload();
      return null;
    }
    return (
      <div id="page" className="page">
        <LandingNav {...this.props} />
        <LandingHero {...this.props} />
        {/* <LandingBrands {...this.props} /> */}
        <LandingContent1 {...this.props} />
        <LandingContent2 {...this.props} />
        {/* <LandingScreenshots {...this.props} /> */}
        {/* <LandingVideo {...this.props} /> */}
        <LandingFeatures {...this.props} />
        <LandingPricing {...this.props} />
        {/* <LandingStatistics {...this.props} /> */}
        <LandingFeedback {...this.props} />
        {/* <LandingTeam {...this.props} /> */}
        {/* <LandingApps {...this.props} /> */}
        <LandingDownload {...this.props} />
        <LandingFAQ {...this.props} />
        <LandingContact {...this.props} />
        {/* <LandingNewsletter {...this.props} /> */}
        <LandingFooter {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isVerifying: state.auth.isVerifying,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(LandingPage);
